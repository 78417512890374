import axios from "../../axios";
import * as actionTypes from "./actionTypes";

export const userList = (userList) => {
  return {
    type: actionTypes.USER_LIST,
    userList: userList,
  };
};

export const setCurrentUserRole = (role) => {
  return {
    type: actionTypes.SET_CURRENT_USER_ROLE,
    currentUserRole: role,
  };
};

export const fetchUserList = (token) => {
  return (dispatch) => {
    axios
      .get("/user-api/user/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(userList(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};

export const fetchCurrentUserRole = (sso, token) => {
  return (dispatch) => {
    axios
      .get(`/user-api/user/${sso}/`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(setCurrentUserRole(response.data.role));
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};
