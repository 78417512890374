import * as actionTypes from "../actions/actionTypes";

const initialState = {
  showModal: false,
};

const showModal = (state, action) => {
  return Object.assign({}, state, {
    signalMsg: action.signalMsg,
    showModal: action.showModal,
  });
};

const hideModal = (state, action) => {
  return Object.assign({}, state, {
    showModal: action.showModal,
    signalMsg: action.signalMsg,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ERROR_MODAL:
      return showModal(state, action);
    case actionTypes.HIDE_ERROR_MODAL:
      return hideModal(state, action);
    default:
      return state;
  }
};

export default reducer;
