export const dateConversion = (str) => {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const DateFormater = (data) => {
  if (typeof data === "undefined" || data === null) return;
  let time = null;
  time = new Date(data);
  return time.toLocaleDateString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};
