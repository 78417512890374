import React from "react";
import "./NoRole.css";

const NoRole = () => (
  <div className="norole">
    <h3>
      No role is assigned to you, please "Contact Studio MTCE x45866 to gain
      access"
    </h3>
  </div>
);

export default NoRole;
