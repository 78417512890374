import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./LiveShotDetails.css";
import moment from "moment";
import CommanModal from "../../shared/commanModal/CommanModal.js";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import Modal from "../../components/UI/Modal/Modal";
import ManageVenue from "../../components/manageVenue/ManageVenue";
import slateImagePlaceholder from "../../assets/images/slateImage.png";
const LiveShotDetails = (props) => {
  const {
    formInfo,
    showList,
    onUpdateLiveshotDetails,
    queryParams,
    userList,
    signalMsg,
    showModal,
    onHideModal,
    // studioTruckList,
    token,
    currentUser,
  } = props;
  // const initialValues = {};
  const [poNo, setPoNo] = useState("");
  const [notes, setNotes] = useState("");
  const [stId, setStId] = useState();
  const [venueContact, setVenueContact] = useState("");
  const [venuePhone, setVenuePhone] = useState("");
  const [venueName, setVenueName] = useState("");
  const [venueRate, setVenueRate] = useState("");
  const [liveshotName, setLiveshotname] = useState("");
  const [canceledBy, setCanceledBy] = useState("");
  const [hitTime, setHitTime] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [cancelTime, setCancelTime] = useState("");
  const [approveTime, setApproveTime] = useState("");
  const [showId, setShowId] = useState();
  const [abstractLiveshotId, setAbstractLiveshotId] = useState();
  const [liveshotId, setLiveshotId] = useState();
  const [canvasId, setCanvasId] = useState();
  const [slateImageUrl, setSlateImageUrl] = useState();
  const [selectedAirDate, setSelectedAirDate] = useState(null);
  const [selectedCancelDate, setSelectedCancelDate] = useState(null);
  const [selectedApproveDate, setSelectedApproveDate] = useState(null);
  const [disabled] = useState(true);
  const [buttonBgd, setButtonBgd] = useState(false);
  const [updateButtonBgd, setUpdateButtonBgd] = useState(false);
  const [manageVenueDetails, setManageVenueDetails] = useState(false);
  // const [activeSuggestion, setActiveSuggestion] = useState(0);
  // const [filteredSuggestions, setFilteredSuggestions] = useState(initialValues);
  // const [showSuggestions, setShowSuggestions] = useState(false);
  // const [userName, setUserName] = useState();
  // let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  useEffect(() => {
    if (formInfo) {
      setPoNo(formInfo.poNo);
      setNotes(formInfo.hitNote);
      setVenueContact(formInfo.studioContact);
      setVenuePhone(formInfo.studioPhone);
      setVenueName(formInfo.studioName);
      setVenueRate(formInfo.ratePer30Min);
      setLiveshotname(formInfo.abstractLiveshotName);
      if (formInfo.canceledBy) {
        const userNameList = userList.find(
          (element) => element.sso === parseInt(formInfo.canceledBy)
        );
        if (userNameList !== undefined) {
          setCanceledBy(userNameList.userName);
        }
      } else {
        setCanceledBy(formInfo.canceledBy);
      }
      if (formInfo.invoiceVerifiedBy) {
        const userNameList = userList.find(
          (element) => element.sso === parseInt(formInfo.invoiceVerifiedBy)
        );
        if (userNameList !== undefined) {
          setApprovedBy(userNameList.userName);
        }
      } else {
        setApprovedBy(formInfo.invoiceVerifiedBy);
      }
      setHitTime(formInfo.hitTime);
      setShowId(formInfo.showId);
      setStId(formInfo.stId);
      setAbstractLiveshotId(formInfo.abstractLiveshotId);
      setLiveshotId(formInfo.liveshotId);
      setCanvasId(formInfo.canvasId);
      setSlateImageUrl(formInfo.slateImageUrl);
      let airDate = formInfo.showDate
        ? moment(formInfo.showDate).format("LL")
        : "";
      let showdate = new Date(airDate);
      setSelectedAirDate(showdate);
      let canceledDateTime = null;
      if (formInfo.canceledOn && formInfo.canceledOn !== null) {
        canceledDateTime = DateFormater(formInfo.canceledOn);
      } else {
        setSelectedCancelDate("");
        setCancelTime("");
      }
      if (canceledDateTime) {
        let cancelDate = new Date(canceledDateTime[1]);
        setSelectedCancelDate(cancelDate);
        setCancelTime(canceledDateTime[0]);
      }

      let approveDateTime = null;
      if (formInfo.invoiceVerifiedOn && formInfo.invoiceVerifiedOn !== null) {
        approveDateTime = DateFormater(formInfo.invoiceVerifiedOn);
      } else {
        setSelectedApproveDate("");
        setApproveTime("");
      }
      if (approveDateTime) {
        let approveDate = new Date(approveDateTime[1]);
        setSelectedApproveDate(approveDate);
        setApproveTime(approveDateTime[0]);
      }
    }
  }, [formInfo, userList]);

  const DateFormater = (date) => {
    let data = [];
    if (date === null) return;
    let dateFormat = null;
    dateFormat = new Date(date);
    let timeValue = dateFormat.toLocaleTimeString(dateFormat, {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    let dateValue = moment(dateFormat).format("LL");
    data.push(timeValue);
    data.push(dateValue);
    return data;
  };

  const onUpdateLiveShot = (event) => {
    event.preventDefault();
    const formData = {};
    setButtonBgd(true);
    setTimeout(() => {
      setButtonBgd(false);
    }, 1500);
    formData.stId = stId;
    formData.abstractLiveshotId = abstractLiveshotId;
    formData.poNo = poNo;
    formData.abstractLiveshotName = liveshotName;
    formData.liveshotId = liveshotId;
    if (hitTime) {
      formData.hitTime = hitTime;
    } else {
      formData.hitTime = "";
    }
    formData.canvasId = canvasId;
    formData.hitNote = notes;
    let updatedBy = userId;
    onUpdateLiveshotDetails(updatedBy, formData, queryParams, token);
  };

  const onUpdateApproveLiveshot = (event) => {
    event.preventDefault();
    const formData = {};
    setUpdateButtonBgd(true);
    setTimeout(() => {
      setUpdateButtonBgd(false);
    }, 1500);
    formData.stId = stId;
    formData.abstractLiveshotId = abstractLiveshotId;
    formData.poNo = poNo;
    formData.abstractLiveshotName = liveshotName;
    formData.liveshotId = liveshotId;
    if (hitTime) {
      formData.hitTime = hitTime;
    } else {
      formData.hitTime = "";
    }
    formData.invoiceVerified = true;
    formData.invoiceVerifiedBy = parseInt(userId);
    formData.invoiceVerifiedOn = new Date().toISOString().split(".")[0] + "Z";
    formData.canvasId = canvasId;
    formData.hitNote = notes;
    let updatedBy = userId;
    onUpdateLiveshotDetails(updatedBy, formData, queryParams, token);
  };

  const onClear = (e) => {
    e.preventDefault();
    if (formInfo) {
      setPoNo(formInfo.poNo);
      setNotes(formInfo.hitNote);
      setVenueContact(formInfo.studioContact);
      setVenuePhone(formInfo.studioPhone);
      setVenueName(formInfo.studioName);
      setVenueRate(formInfo.ratePer30Min);
      setLiveshotname(formInfo.abstractLiveshotName);
      setHitTime(formInfo.hitTime);
    }
  };
  // const onClick = (e) => {
  //   setActiveSuggestion(0);
  //   setFilteredSuggestions(initialValues);
  //   setShowSuggestions(false);
  //   setVenueName(e.target.innerText);
  //   let value = e.target.innerText;
  //   let selectedStudio = studioTruckList.filter(
  //     (list) => list.studioName === value && list.softDelete === false
  //   );
  //   if (selectedStudio) {
  //     let Id = selectedStudio[0].stId;
  //     setStId(Id);
  //     setVenueContact(selectedStudio[0].studioContact);
  //     setVenuePhone(selectedStudio[0].studioPhone);
  //     let rate = (selectedStudio[0].studioHourRate) / 2;
  //     setVenueRate(rate);
  //   }
  // };

  // const studioNameArr = studioTruckList
  //   .filter((list) => list.softDelete === false)
  //   .map((list) => {
  //     return list.studioName;
  //   });

  // const studioList = studioNameArr.filter((list) => {
  //   return list != null;
  // });

  // const onStudioNameChange = (e) => {
  //   setVenueName(e.target.value);
  //   let value = e.target.value;
  //   if (value !== undefined) {
  //     const filSuggestion = studioList.filter(
  //       (suggestion) =>
  //         suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
  //     );
  //     setActiveSuggestion(activeSuggestion);
  //     setFilteredSuggestions(filSuggestion);
  //     setShowSuggestions(true);
  //   }
  // };
  // const onKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     setShowSuggestions(false);
  //   }
  // };

  // let suggestionsListComponent;
  // if (showSuggestions && venueName) {
  //   if (filteredSuggestions.length) {
  //     let uniqueFilteredSuggestions = filteredSuggestions.filter(
  //       (item, pos) => {
  //         return filteredSuggestions.indexOf(item) === pos;
  //       }
  //     );
  //     uniqueFilteredSuggestions = uniqueFilteredSuggestions.sort(function (a, b) {
  //       return a.toLowerCase().localeCompare(b.toLowerCase());
  //     });
  //     suggestionsListComponent = (
  //       <ul className="suggestions">
  //         {uniqueFilteredSuggestions.map((suggestion, index) => {
  //           return (
  //             <li key={index} onClick={onClick}>
  //               {suggestion}
  //             </li>
  //           );
  //         })}
  //       </ul>
  //     );
  //   }
  // }

  const onManageVenueDetails = (event) => {
    event.preventDefault();
    setManageVenueDetails(true);
  };
  const onCancelManageVenueDetails = () => {
    setManageVenueDetails(false);
  };
  let commanModal = null;
  if (showModal) {
    commanModal = (
      <Fragment>
        <Backdrop show={showModal} clicked={onHideModal} />
        <CommanModal closeModal={onHideModal} message={signalMsg} />
      </Fragment>
    );
  }

  let venueModal = null;
  if (manageVenueDetails) {
    venueModal = (
      <Modal
        show={manageVenueDetails}
        modalClosed={onCancelManageVenueDetails}
        className="manageshowsmodal"
      >
        <ManageVenue
          modalClosed={onCancelManageVenueDetails}
          selectstId={(name) => setStId(name)}
          selectVenueName={(name) => setVenueName(name)}
          selectVenueContact={(contact) => setVenueContact(contact)}
          selectVenuePhone={(phone) => setVenuePhone(phone)}
          selectVenueRate={(rate) => setVenueRate(rate)}
        />
      </Modal>
    );
  }

  return (
    <Fragment>
      {commanModal}
      {venueModal}
      <div className="ls-detail-main">
        <h5>Live Shot Details</h5>
        <div className="ls_detail_container">
          <form>
            <div className="row">
              <div className="col-md-2 slate-img">
                <label>Key Art</label> <br />
                {slateImageUrl ? (
                  <img src={slateImageUrl} alt="Slate Thumbnail" />
                ) : (
                  <img src={slateImagePlaceholder} alt="Slate Thumbnail" />
                )}
              </div>
              <div className="col-md-3 venue-details">
                <div className="ls-venue">
                  <label>Venue</label> <br />
                  <input
                    type="text"
                    name="venue name"
                    defaultValue={venueName ? venueName : ""}
                    disabled={disabled}
                  />
                </div>
                {/* {suggestionsListComponent} */}
                <div className="ls-venue-contact">
                  <label>Venue Contact</label> <br />
                  <input
                    type="text"
                    name="venue contact"
                    defaultValue={venueContact}
                    disabled={disabled}
                  />
                </div>
                <div className="row">
                  <div className="ls-venue-phone">
                    <label>Venue Phone #</label> <br />
                    <input
                      type="text"
                      name="venue phone number"
                      defaultValue={venuePhone}
                      disabled={disabled}
                    />
                  </div>
                  <div className="ls-venue-rate">
                    <label>Venue Rate</label> <br />
                    <input
                      type="text"
                      name="venue rate"
                      defaultValue={venueRate}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="row managebtn">
                  <button className="venuebtn" onClick={onManageVenueDetails}>
                    Manage Venues
                  </button>
                </div>
              </div>
              <div className="col-md-3">
                <div className="shownamebox">
                  <label>Show Name</label>
                  <select name="showName" value={showId}>
                    <option></option>
                    {showList.map((item, idx) => {
                      return (
                        <option value={item.showId} key={item.showId}>
                          {item.showName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-12  ls-poNumber">
                    <label>PO Number</label> <br />
                    <input
                      type="text"
                      name="poNo"
                      value={poNo}
                      // maxLength="10"
                      onChange={(e) => setPoNo(e.target.value)}
                    />
                  </div>
                  <div className=" col-lg-6 col-12 ls-notes">
                    <label>Notes</label> <br />
                    <textarea
                      name="notes"
                      row="7"
                      value={notes ? notes : ""}
                      onChange={(e) => setNotes(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-12 guest-cancel-box">
                <div className="ls-guest-name">
                  <label>Guest Name</label> <br />
                  <input
                    type="text"
                    name="liveshot name"
                    value={liveshotName}
                    onChange={(e) => setLiveshotname(e.target.value)}
                  />
                </div>
                <div className="">
                  <div className="ls-canceled-by">
                    <label>Canceled By</label> <br />
                    <input
                      type="text"
                      name="canceled by"
                      value={canceledBy ? canceledBy : ""}
                      disabled={disabled}
                    />
                  </div>
                  <div className="ls-canceled-on">
                    <label>Canceled On(LocalTime)</label>
                    <div className="cancel-date">
                      <Datepicker
                        className="date-value"
                        dateFormat="MMMM d, yyyy"
                        selected={selectedCancelDate ? selectedCancelDate : ""}
                        disabled={disabled}
                      />
                      <i className="fa fa-calendar lscancelicon"></i>
                    </div>
                    <div className="cancel-time">
                      <input
                        type="text"
                        name="canceled time"
                        value={cancelTime ? cancelTime : ""}
                        disabled={disabled}
                      />
                      <i className="fas fa-clock ls-cancelclockicon"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-12">
                <div className="airdate-ls inputfield">
                  <label>Air Date</label>
                  <div className="airdate-ls-fields">
                    <Datepicker
                      className="date-value"
                      dateFormat="MMMM d, yyyy"
                      selected={selectedAirDate ? selectedAirDate : ""}
                      disabled={disabled}
                    />
                    <i className="fa fa-calendar lscalendaricon"></i>
                  </div>
                </div>
                <div className="ls-hittime">
                  <div className="ls-hittime-field">
                    <label>Hit Time(ET)</label> <br />
                    <input
                      type="text"
                      value={hitTime ? hitTime : ""}
                      onChange={(e) => setHitTime(e.target.value)}
                    />
                    <div>
                      <i className="fas fa-clock ls-clockicon"></i>
                    </div>
                  </div>
                </div>
                <div className="ls-approvedby">
                  <label>Invoice Approved By</label> <br />
                  <input
                    type="text"
                    name="Approved By"
                    defaultValue={approvedBy ? approvedBy : ""}
                    disabled={disabled}
                  />
                </div>
                <div className="ls-approvedon">
                  <label>Approved On(LocalTime)</label>
                  <div className="approve-date">
                    <Datepicker
                      className="date-value"
                      dateFormat="MMMM d, yyyy"
                      selected={selectedApproveDate ? selectedApproveDate : ""}
                      disabled={disabled}
                    />
                    <i className="fa fa-calendar lsapproveicon"></i>
                  </div>
                </div>
                <div className="approve-time">
                  <input
                    type="text"
                    name="approve time"
                    defaultValue={approveTime ? approveTime : ""}
                    disabled={disabled}
                  />
                  <i className="fas fa-clock ls-approveclockicon"></i>
                </div>
              </div>
            </div>
            <div className="row three-btn">
              <button className="cancelbtn" onClick={onClear}>
                CANCEL
              </button>
              <button
                className={buttonBgd ? "updatebtnBgd" : "updatebtn"}
                onClick={onUpdateLiveShot}
              >
                UPDATE
              </button>
              <button
                className={updateButtonBgd ? "uabtnBgd" : "uabtn"}
                onClick={onUpdateApproveLiveshot}
              >
                UPDATE & APPROVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showList: state.liveShotSearch.showList,
    studioTruckList: state.liveShotSearch.studioTruckList,
    queryParams: state.liveShotSearch.queryParams,
    userList: state.user.userList,
    signalMsg: state.liveshotDetails.signalMsg,
    showModal: state.liveshotDetails.showModal,
    manageVenue: state.liveshotDetails.manageVenue,
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateLiveshotDetails: (updatedBy, formData, queryParams, token) =>
      dispatch(
        actions.updateLiveshotDetails(updatedBy, formData, queryParams, token)
      ),
    onHideModal: () => dispatch(actions.hideErrorModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveShotDetails);
