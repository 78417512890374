import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import moment from "moment";
import { DateFormater } from "../../shared/utility";
export const fetchShowListSuccess = (res) => {
  return {
    type: actionTypes.SHOW_LIST,
    showList: res,
  };
};

export const fetchStudioTruckListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_STUDIOTRUCK_LIST,
    studioTruckList: lists,
  };
};

export const fetchLiveshotsFromSearchSuccess = (res, queryParams) => {
  return {
    type: actionTypes.LIVESHOT_SEARCH_DATA,
    searchList: res,
    queryParams: queryParams,
  };
};

export const showLoader = () => {
  return {
    type: actionTypes.SHOW_LOADER,
    showLoader: true,
  };
};

export const closeLoader = () => {
  return {
    type: actionTypes.CLOSE_LOADER,
    showLoader: false,
  };
};

export const fetchShowList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/show/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedShowList = [];
        for (let key in res.data) {
          fetchedShowList.push({
            ...res.data[key],
          });
        }
        const activeShows = fetchedShowList.filter(
          (item) => item.softDelete !== true
        );
        dispatch(fetchShowListSuccess(activeShows));
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };
};

export const fetchStudioTruckList = (token) => {
  return (dispatch) => {
    axios
      .get("liveshot-api/studioTruck/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedStudioTruckList = [];
        for (let key in res.data) {
          const studioTruckList = res.data[key];
          let lastUsedDate, updatedOnDate;
          if (studioTruckList.lastUsedOn) {
            let lastDate = DateFormater(studioTruckList.lastUsedOn);
            lastUsedDate = moment(new Date(lastDate)).format("MM/DD/YYYY");
          } else {
            lastUsedDate = "";
          }
          if (studioTruckList.updatedOn) {
            let updatedDate = DateFormater(studioTruckList.updatedOn);
            updatedOnDate = moment(new Date(updatedDate)).format("MM/DD/YYYY");
          } else {
            updatedOnDate = "";
          }
          fetchedStudioTruckList.push({
            ...studioTruckList,
            ratePer30Min:
              studioTruckList.studioHourRate > 0
                ? studioTruckList.studioHourRate / 2
                : studioTruckList.studioHourRate,
            lastUsedDate: lastUsedDate,
            updatedOnDate: updatedOnDate,
          });
        }
        dispatch(fetchStudioTruckListSuccess(fetchedStudioTruckList));
        dispatch(closeLoader());
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};

export const onSearchForLiveshots = (token, queryParams) => {
  return (dispatch) => {
    const queryString = queryParams.join("&");
    axios
      .get(`liveshot-api/reporting/?${queryString}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedLiveshotsFromLiveshotSearch = [];
        for (let key in res.data) {
          const liveshotInfo = res.data[key];
          let windowEndTime, windowStartTime;
          windowEndTime =
            liveshotInfo.windowEndTime === null
              ? ""
              : liveshotInfo.windowEndTime;
          windowStartTime =
            liveshotInfo.windowStartTime === null
              ? ""
              : liveshotInfo.windowStartTime;
          fetchedLiveshotsFromLiveshotSearch.push({
            ...liveshotInfo,
            window:
              windowStartTime || windowEndTime
                ? windowStartTime + " - " + windowEndTime
                : "",
            ratePer30Min:
              liveshotInfo.studioHourRate > 0
                ? liveshotInfo.studioHourRate / 2
                : liveshotInfo.studioHourRate,
            airDate: liveshotInfo.showDate
              ? moment(liveshotInfo.showDate).format("MM/DD/YYYY")
              : "",
            status: liveshotInfo.canceled ? "CANCELED" : "",
          });
        }
        dispatch(
          fetchLiveshotsFromSearchSuccess(
            fetchedLiveshotsFromLiveshotSearch,
            queryParams
          )
        );
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};

export const downloadToExcel = (token, queryParams) => {
  return (dispatch) => {
    const queryString = queryParams.join("&");
    axios
      .get(`liveshot-api/reporting/download/?${queryString}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};

export const downloadDataExport = (token, queryParams) => {
  return (dispatch) => {
    const queryString = queryParams.join("&");
    axios
      .get(`liveshot-api/liveshot/download?type=csv&${queryString}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};
