import * as actionTypes from "../actions/actionTypes";

const initialState = {
  searchList: [],
  showList: [],
  studioTruckList: [],
  queryParams: [],
  loading: false,
};

const showListSuccess = (state, action) => {
  return Object.assign({}, state, {
    showList: action.showList,
  });
};

const fetchStudioTruckListSuccess = (state, action) => {
  return Object.assign({}, state, {
    studioTruckList: action.studioTruckList,
  });
};

const fetchLiveshotsFromSearchSuccess = (state, action) => {
  return Object.assign({}, state, {
    searchList: action.searchList,
    queryParams: action.queryParams,
  });
};

const showLoader = (state, action) => {
  return Object.assign({}, state, { loading: action.showLoader });
};

const closeLoader = (state, action) => {
  return Object.assign({}, state, { loading: action.showLoader });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LIST:
      return showListSuccess(state, action);
    case actionTypes.FETCH_STUDIOTRUCK_LIST:
      return fetchStudioTruckListSuccess(state, action);
    case actionTypes.LIVESHOT_SEARCH_DATA:
      return fetchLiveshotsFromSearchSuccess(state, action);
    case actionTypes.CLOSE_LOADER:
      return closeLoader(state, action);
    case actionTypes.SHOW_LOADER:
      return showLoader(state, action);
    default:
      return state;
  }
};

export default reducer;
