import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as actions from "../../store/actions/index";
import "./ManageVenue.css";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon-plus.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/save-icon.svg";
import GridComponents from "../../components/manageVenue/OnDeleteVenue";
import Loader_img from "../../assets/images/loader.gif";

const ManageShow = (props) => {
  const {
    modalClosed,
    selectstId,
    selectVenueName,
    selectVenueContact,
    selectVenuePhone,
    selectVenueRate,
    studioTruckList,
    onSaveStudioTruck,
    onUpdateStudioTruck,
    loading,
    onShowLoader,
    onDownloadStudioTruckDetails,
    onUploadStudioTruck,
    userList,
    token,
    currentUser,
  } = props;
  const [filteresStudioTruckList, setFilteresStudioTruckList] = useState(null);
  const [stId, setStId] = useState(null);
  const [venueName, setVenueName] = useState(null);
  const [venueContact, setVenueContact] = useState(null);
  const [venuePhone, setVenuePhone] = useState(null);
  const [venueRate, setVenueRate] = useState(null);
  const [flag, setFlag] = useState(true);
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  useEffect(() => {
    let studioTruckFilteredList = studioTruckList.filter((list) => {
      return list.softDelete === false;
    });
    let studioTruckListValues = studioTruckFilteredList.map((list) => {
      const userNameList = userList.find(
        (element) => element.sso === parseInt(list.updatedBy)
      );
      if (userNameList !== undefined) {
        const updatedItem = {
          ...list,
          updatedByName: userNameList.userName,
        };
        return updatedItem;
      } else {
        const updatedItem = {
          ...list,
          updatedByName: list.updatedBy,
        };
        return updatedItem;
      }
    });
    setFilteresStudioTruckList(studioTruckListValues);
  }, [studioTruckList, userList]);

  const onRowClicked = (e) => {
    setFlag(false);
    setStId(e.data.stId);
    setVenueName(e.data.studioName);
    setVenueContact(e.data.studioContact);
    setVenuePhone(e.data.studioPhone);
    let rate = e.data.studioHourRate / 2;
    setVenueRate(rate);
  };

  const onStudioNameChange = (e) => {
    setVenueName(e.target.value);
  };

  const onClose = () => {
    setFlag(true);
    setStId(null);
    setVenueName(null);
    setVenuePhone(null);
    setVenueContact(null);
    setVenueRate(null);
  };

  const frameworkComponents = {
    actionsRenderer: GridComponents,
  };

  let loader = null;
  if (loading) {
    loader = (
      <div className="loader">
        <img width="70" height="70" src={Loader_img} alt="loader" />
      </div>
    );
  }

  const dateComparator = (date1, date2) => {
    let date1Number = monthToComparableNumber(date1);
    let date2Number = monthToComparableNumber(date2);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  };

  const monthToComparableNumber = (date) => {
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }
    let yearNumber = date.substring(6, 10);
    let dayNumber = date.substring(3, 5);
    let monthNumber = date.substring(0, 2);
    let result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
  };

  const [columns] = useState([
    {
      headerName: "Id",
      field: "stId",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Venue",
      field: "studioName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      filter: true,
      // editable: edit,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellStyle: { border: "none !important" },
    },
    {
      headerName: "Venue Contact",
      field: "studioContact",
      resizable: true,
      cellStyle: { border: "none !important" },
      sortable: true,
      suppressMenu: true,
      suppressNavigable: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Venue Phone#",
      field: "studioPhone",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      suppressNavigable: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Venue Rate",
      field: "ratePer30Min",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Updated By",
      field: "updatedByName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Updated On",
      field: "updatedOnDate",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      comparator: dateComparator,
    },
    {
      headerName: "Last UsedOn",
      field: "lastUsedDate",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressNavigable: true,
      cellClass: "no-border",
      comparator: dateComparator,
    },
    // {
    //   filter: false,
    //   cellRendererFramework: (params) => {
    //     return (
    //       <span className="delete-row">
    //         <i title="Delete" className="fas fa-trash" onClick={onDelete}></i>
    //       </span >
    //     );
    //   },
    // },
    {
      headerName: "",
      colId: "actions",
      cellRenderer: "actionsRenderer",
      editable: false,
      filter: false,
      sortable: false,
    },
  ]);

  const [defaultcolDef] = useState({
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: true,
    cellStyle: { cursor: "pointer" },
  });

  const onSubmit = () => {
    onShowLoader();
    const studioFormData = {};
    studioFormData.studioName = venueName;
    studioFormData.studioContact = venueContact;
    studioFormData.studioPhone = venuePhone;
    studioFormData.studioHourRate = venueRate * 2;
    if (stId !== 0 && stId !== undefined && stId !== null) {
      studioFormData.stId = stId;
      studioFormData.updatedBy = parseInt(userId);
      studioFormData.createdBy = parseInt(userId);
      onUpdateStudioTruck(studioFormData, token);
      onClose();
    } else {
      studioFormData.updatedBy = parseInt(userId);
      studioFormData.createdBy = parseInt(userId);
      onSaveStudioTruck(studioFormData, token);
      onClose();
    }
  };
  const onCloseModal = () => {
    modalClosed(false);
  };
  const onSelect = () => {
    selectstId(stId);
    modalClosed(false);
    selectVenueName(venueName);
    selectVenueContact(venueContact);
    selectVenuePhone(venuePhone);
    selectVenueRate(venueRate);
  };
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    onUploadStudioTruck(fileUploaded, token);
    event.target.value = null;
  };
  return (
    <Fragment>
      {loader}
      <div className="manage-venue-container">
        <div className="heading">
          <h3>Manage Venues</h3>
        </div>
        <div className="row">
          <div className="venue">
            <form>
              <div className="venue-name">
                <label>Venue</label>
                <br />
                <input
                  type="text"
                  name="venue name"
                  value={venueName ? venueName : ""}
                  onChange={(e) => onStudioNameChange(e)}
                />
              </div>
              <div className="venue-contact">
                <label>Venue Contact</label>
                <br />
                <input
                  type="text"
                  name="venue contact"
                  value={venueContact ? venueContact : ""}
                  onChange={(e) => setVenueContact(e.target.value)}
                />
              </div>
              <div className="venue-phone">
                <label>Venue Phone #</label> <br />
                <input
                  type="text"
                  name="venue phone number"
                  value={venuePhone ? venuePhone : ""}
                  onChange={(e) => setVenuePhone(e.target.value)}
                />
              </div>
              <div className="venue-rate">
                <label>Venue Rate</label> <br />
                <input
                  type="number"
                  name="venue rate"
                  value={venueRate ? venueRate : ""}
                  onChange={(e) => setVenueRate(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="clear-Button"
                onClick={onClose}
                title="Unset"
              >
                X
              </button>
              <div className="add-button">
                {flag ? (
                  <AddIcon onClick={() => onSubmit()} />
                ) : (
                  <SaveIcon onClick={() => onSubmit()} />
                )}
              </div>
            </form>
          </div>
          <div className="download-upload">
            <div className="download-csv">
              <button
                title="Download CSV"
                onClick={() => onDownloadStudioTruckDetails(token)}
              >
                <i className="fas fa-download" aria-hidden="true">
                  <input
                    type="file"
                    id="download-button"
                    style={{ display: "none" }}
                  />
                </i>
              </button>
            </div>
            <div className="upload-csv">
              <button title="Upload CSV" onClick={handleClick}>
                <i className="fas fa-upload" aria-hidden="true">
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={handleChange}
                  />
                </i>
              </button>
            </div>
          </div>
        </div>
        <div className="ag-theme-alpine venue_table_container">
          <AgGridReact
            columnDefs={columns}
            defaultColDef={defaultcolDef}
            rowData={filteresStudioTruckList}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize="5"
            rowSelection={"single"}
            onRowClicked={onRowClicked}
            suppressRowClickSelection={true}
            frameworkComponents={frameworkComponents}
          ></AgGridReact>
        </div>
        <div className="row three-btn">
          <button className="cancelbtn" onClick={onCloseModal}>
            CANCEL
          </button>
          <button className="updatebtn" onClick={onSelect}>
            {" "}
            SELECT
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studioTruckList: state.liveShotSearch.studioTruckList,
    loading: state.liveShotSearch.loading,
    userList: state.user.userList,
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveStudioTruck: (studioFormData, token) =>
      dispatch(actions.saveStudioTruck(studioFormData, token)),
    onUpdateStudioTruck: (studioFormData, token) =>
      dispatch(actions.updateStudioTruck(studioFormData, token)),
    onShowLoader: () => dispatch(actions.showLoader()),
    onDownloadStudioTruckDetails: (token) =>
      dispatch(actions.downloadStudioTruckDetails(token)),
    onUploadStudioTruck: (fileUploaded, token) =>
      dispatch(actions.uploadStudioTruck(fileUploaded, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageShow);
