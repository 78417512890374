import axios from "../../axios";
import * as actions from "../../store/actions/index";
import * as actionTypes from "../actions/actionTypes";
import * as Constants from "../../shared/Constants";

export const saveStudioTruckSuccess = (resdata) => {
  return {
    type: actionTypes.STUDIOTRUCKSAVE_OBJECT_SUCCESS,
    successMsg: resdata,
  };
};

export const saveStudioTruck = (studioTruckObj, token) => {
  return (dispatch) => {
    axios
      .post("liveshot-api/studioTruck/", studioTruckObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch(
            saveStudioTruckSuccess({
              resultData: Constants.STUDIOTRUCK_SAVE_SUCCESS_MSG,
              statusCode: res.status,
              studioData: res.data,
            })
          );
          dispatch(actions.fetchStudioTruckList(token));
        }
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };
};

export const updateStudioTruck = (studioTruckObj, token) => {
  return (dispatch) => {
    axios
      .put("liveshot-api/studioTruck/", studioTruckObj, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            saveStudioTruckSuccess({
              resultData: Constants.STUDIOTRUCK_UPDATE_SUCCESS_MSG,
              statusCode: res.status,
              studioData: res.data,
            })
          );
          dispatch(actions.fetchStudioTruckList(token));
        }
      })
      .catch((err) => {
        console.log("error:", err);
      });
  };
};

export const deleteStudioTruck = (Id, token) => {
  return (dispatch) => {
    axios
      .delete(`liveshot-api/studioTruck/${Id}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(actions.fetchStudioTruckList(token));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const downloadStudioTruckDetails = (token) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/studioTruck/download`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .split(";")[0];
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};

export const uploadStudioTruck = (file, token) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("file", file);
    console.log("file", formData);
    axios
      .post(`liveshot-api/studioTruck/upload`, formData, {
        withCredentials: true,
        headers: {
          Authorization: token !== "" ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log("error ", err);
      });
  };
};
