import React from "react";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import "./ManageVenue.css";

const OnDeleteVenue = (props) => {
  const { onDeleteStudioTruck, onShowLoader, token } = props;
  const deleteRow = () => {
    onShowLoader();
    let data = props.data;
    let confirm = true;

    confirm = window.confirm(`Are you sure you want to delete this row:`);
    if (confirm) {
      let stId = data.stId;
      onDeleteStudioTruck(stId, token);
    }
  };

  return (
    <div>
      <span className="delete-row">
        {" "}
        <i
          title="Delete"
          className="fas fa-trash"
          onClick={() => deleteRow()}
        ></i>
      </span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteStudioTruck: (Id, token) =>
      dispatch(actions.deleteStudioTruck(Id, token)),
    onShowLoader: () => dispatch(actions.showLoader()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OnDeleteVenue);
