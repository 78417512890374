import React from "react";
import "./CommanModal.css";

const CommanModal = (props) => {
  return (
    <div className="popup-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            <span className="failureMsg">ERROR</span>
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={props.closeModal}
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <p>{props.message.resultData}</p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={props.closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommanModal;
