import axios from "../../axios";
import * as actions from "../../store/actions/index";
import * as actionTypes from "./actionTypes";
import * as Constants from "../../shared/Constants";

export const showErrorModal = (resdata) => {
  return {
    type: actionTypes.SHOW_ERROR_MODAL,
    signalMsg: resdata,
    showModal: true,
  };
};
export const hideErrorModal = () => {
  return {
    type: actionTypes.HIDE_ERROR_MODAL,
    showModal: false,
    signalMsg: {},
  };
};
export const updateLiveshotDetails = (
  updatedBy,
  formData,
  queryParams,
  token
) => {
  return (dispatch) => {
    axios
      .patch(`liveshot-api/reporting/?updatedBy=${updatedBy}`, formData, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(actions.onSearchForLiveshots(token, queryParams));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.data.message) {
          dispatch(
            showErrorModal({
              resultData: err.response.data.message,
              statusCode: err.response.status,
            })
          );
        } else {
          dispatch(
            showErrorModal({
              resultData: Constants.LIVESHOT_UPDATE_FAILURE_MSG,
              statusCode: err.response.status,
            })
          );
        }
      });
  };
};
