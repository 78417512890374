import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import LiveShotDetails from "../liveShotDetails/LiveShotDetails";
import { dateConversion } from "../../shared/utility";
import "./Home.css";
import Download_icon from "../../assets/icons/download-icon.svg";
import { Multiselect } from "multiselect-react-dropdown";
import * as Constants from "../../shared/Constants";

const Home = (props) => {
  const {
    onFetchShowList,
    showList,
    onFetchStudioTruckList,
    studioTruckList,
    searchList,
    onSearchForLiveshots,
    onFetchUserList,
    onDownloadToExcel,
    onDownloadDataExport,
    token,
    currentUser,
    currentUserRole,
    onFetchCurrentUserRole,
  } = props;
  const [selectedRow, setSelectedRow] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [poNumber, setPoNumber] = useState("");
  const [name, setName] = useState("");
  const [showName, setShowName] = useState("");
  const [venueName, setVenueName] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [abstractFieldDisable, setAbstractFieldDisable] = useState(false);
  const [showFieldDisable, setShowFieldDisable] = useState(false);
  const [stId, setStId] = useState("");
  const [canceled, setCanceled] = useState(false);
  const [params, setParams] = useState([]);
  const [rangeParam, setRangeParam] = useState(false);
  const [multiselectRef] = useState(React.createRef());
  useEffect(() => {
    onFetchShowList(token);
    onFetchStudioTruckList(token);
    onFetchUserList(token);
  }, [onFetchShowList, onFetchStudioTruckList, onFetchUserList, token]);

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  useEffect(() => {
    if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
      onFetchCurrentUserRole(userId, token);
    }
  }, [onFetchCurrentUserRole, token, userId]);

  let permissionList = null;
  let reportingAccessPerm = null;
  if (currentUserRole) {
    permissionList = currentUserRole.permission;
    if (permissionList.length > 0) {
      reportingAccessPerm = permissionList.find(
        (usr) => usr.appPermissionDesc === Constants.REPORTING_ACCESS_PERMISSION
      );
    }
  }

  const onClear = (e) => {
    e.preventDefault();
    setName("");
    setPoNumber("");
    setSelectedDate("");
    setSelectedToDate("");
    setShowName("");
    setVenueName("");
    setStartTime("");
    setEndTime("");
    setRangeParam(false);
    setAbstractFieldDisable(false);
    setShowFieldDisable(false);
    resetValues();
  };

  const studioNameArr = studioTruckList
    .filter((list) => list.softDelete === false)
    .map((list) => {
      return list.studioName;
    });

  const studioList = studioNameArr.filter((list) => {
    return list != null;
  });

  const onVenueChange = (e) => {
    e.target.value ? setShowFieldDisable(true) : setShowFieldDisable(false);
    setVenueName(e.target.value);
    let value = e.target.value;
    if (value !== undefined) {
      const filSuggestion = studioList.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredSuggestions(filSuggestion);
      setShowSuggestions(true);
    }
  };

  let suggestionsListComponent = null;
  if (showSuggestions && venueName.trim() !== "") {
    if (filteredSuggestions.length) {
      let uniqueFilteredSuggestions = filteredSuggestions.filter(
        (item, pos) => {
          return filteredSuggestions.indexOf(item) === pos;
        }
      );
      uniqueFilteredSuggestions = uniqueFilteredSuggestions.sort(function (
        a,
        b
      ) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      suggestionsListComponent = (
        <ul className="suggestions">
          {uniqueFilteredSuggestions.map((suggestion, index) => {
            return (
              <li key={index} onClick={(e) => onSelectVenue(e)}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  const onSelectVenue = (e) => {
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setVenueName(e.target.innerText);
    let value = e.target.innerText;
    let selectedStudio = studioTruckList.filter(
      (list) => list.studioName === value && list.softDelete === false
    );
    if (selectedStudio.length > 0) {
      let Id = selectedStudio[0].stId;
      setStId(Id);
    }
  };

  const [columns] = useState([
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellStyle: function (params) {
        if (params.value === "CANCELED") {
          //mark police cells as red
          return { color: "red" };
        } else {
          return null;
        }
      },
    },
    {
      headerName: "PO#",
      field: "poNo",
      resizable: true,
      cellStyle: { border: "none !important" },
      sortable: true,
      suppressMenu: true,
      suppressNavigable: true,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Guest Name",
      field: "abstractLiveshotName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      filter: false,
    },
    {
      headerName: "Venue",
      field: "studioName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Rate (/30 min)",
      field: "ratePer30Min",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Show",
      field: "showName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Air Date",
      field: "airDate",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
    },
    {
      headerName: "Window",
      field: "window",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      // valueFormatter: (Params) => DateFormater(Params.data.windowStartTime),
    },
    {
      headerName: "Invoice Approved",
      field: "invoiceVerified",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      filter: false,
      cellStyle: function (params) {
        if (params.value === true) {
          //mark police cells as red
          return {
            color: "white",
            "background-color": "green",
            "text-align": "center",
          };
        } else {
          return {
            color: "white",
            "background-color": "#72798E",
            "text-align": "center",
          };
        }
      },
      cellRendererFramework: (params) => {
        return params.data.invoiceVerified ? (
          <span className="verify-rows">
            <i className="fas fa-check-circle"></i>
          </span>
        ) : (
          <span className="unverify-rows">
            <i className="fas fa-minus-circle"></i>
          </span>
        );
      },
    },
  ]);

  // const DateFormater = (date) => {
  //   if (date === null) return;
  //   let Time = null;
  //   Time = new Date(date);
  //   return Time.toLocaleTimeString(Time, {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false,
  //   });
  // };

  const [defaultcolDef] = useState({
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: false,
    cellStyle: { cursor: "pointer" },
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    let selectedRows = gridApi.getSelectedRows();
    setSelectedRow(selectedRows[0]);
  };

  // const onChangeShowName = (e) => {
  //   setShowName(e.target.value);
  //   if (e.target.value) {
  //     setAbstractFieldDisable(true);
  //   } else {
  //     setAbstractFieldDisable(false);
  //   }
  // };

  const onSelect = (selectedArray, selectedElement) => {
    if (selectedElement) {
      setAbstractFieldDisable(true);
    } else {
      setAbstractFieldDisable(false);
    }
    let element = [];
    selectedArray.forEach((each) => {
      element.push(each.showId);
    });
    if (element.length > 0) {
      setShowName(element);
    } else {
      setShowName("");
    }
  };

  const onRemove = (selectedArray) => {
    let element = [];
    selectedArray.forEach((each, id) => {
      element.push(each.showId);
    });
    if (element.length > 0) {
      setShowName(element);
    } else {
      setShowName("");
    }
  };

  const resetValues = () => {
    multiselectRef.current.resetSelectedValues();
  };

  const onChangeDate = (date) => {
    setSelectedDate(date);
    // setAbstractFieldDisable(true);
    if (!rangeParam) {
      setSelectedToDate(date);
    }
  };
  const onChangeToDate = (date) => {
    setSelectedToDate(date);
    // setAbstractFieldDisable(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const queryParams = [];
    if (
      selectedDate === null &&
      selectedToDate === null &&
      showName === "" &&
      poNumber === "" &&
      name === "" &&
      venueName === "" &&
      startTime === "" &&
      endTime === ""
    ) {
      alert("please fill the form correctly.");
      return;
    }
    if (canceled) {
      queryParams.push("canceled=" + encodeURIComponent(canceled));
    }
    if (abstractFieldDisable) {
      if (showName !== "") {
        queryParams.push("showId=" + encodeURIComponent(showName));
      }
    }
    if (showFieldDisable) {
      if (poNumber !== "") {
        queryParams.push("poNo=" + encodeURIComponent(poNumber));
      }
      if (name !== "") {
        queryParams.push("guestName=" + encodeURIComponent(name));
      }
      if (venueName !== "") {
        queryParams.push("stId=" + encodeURIComponent(stId));
      }
      // if (startTime !== "") {
      //   queryParams.push("windowStartFrom=" + encodeURIComponent(startTime));
      // }
      // if (endTime !== "") {
      //   queryParams.push("windowStartTo=" + encodeURIComponent(endTime));
      // }
    }
    if (selectedDate !== null && selectedDate !== "") {
      const showDate = dateConversion(selectedDate);
      queryParams.push("showDateFrom=" + encodeURIComponent(showDate));
    }
    if (selectedToDate !== null && selectedToDate !== "") {
      const showToDate = dateConversion(selectedToDate);
      queryParams.push("showDateTo=" + encodeURIComponent(showToDate));
    }
    setParams(queryParams);
    onSearchForLiveshots(token, queryParams);
  };
  const onExport = (e) => {
    e.preventDefault();
    if (selectedDate === null && selectedToDate === null && showName === "") {
      alert("please fill the form correctly.");
      return;
    }
    const queryParams = [];
    if (abstractFieldDisable) {
      if (selectedDate && selectedToDate && showName) {
        if (selectedDate !== null && selectedDate !== "") {
          const showDate = dateConversion(selectedDate);
          queryParams.push("startDate=" + encodeURIComponent(showDate));
        }
        if (selectedToDate !== null && selectedToDate !== "") {
          const showToDate = dateConversion(selectedToDate);
          queryParams.push("endDate=" + encodeURIComponent(showToDate));
        }
        if (showName !== "") {
          queryParams.push("showId=" + encodeURIComponent(showName));
        }

        setParams(queryParams);
        onDownloadDataExport(token, queryParams);
      }
    }
  };
  const onDownloadExcel = () => {
    onDownloadToExcel(token, params);
  };
  const onChangeRange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (value) {
      setRangeParam(true);
    } else {
      setRangeParam(false);
      setSelectedToDate(selectedDate);
    }
  };

  const style = {
    chips: {
      height: "30px",
    },
    searchBox: {
      border: "none",
      borderBottom: "1px solid #fff",
      borderRadius: "0px",
    },
    multiselectContainer: {
      color: "white",
      width: "96%",
    },
  };

  return (
    <Fragment>
      <div className="search-form-container">
        <div className="search-ls-container">
          <p className="search-ls-heading">Search Live Shots</p>
          <div className="search-ls-inner-container">
            <form>
              <div className="row">
                <div className="col-lg-1 col-md-1 col-4 cancelbox">
                  <input
                    type="checkbox"
                    disabled={
                      !abstractFieldDisable && !showFieldDisable
                        ? true
                        : false || !reportingAccessPerm
                    }
                    className="chkbox-canceled"
                    onChange={() => setCanceled(!canceled)}
                  />
                  <label>Canceled</label>
                </div>
                <div className="col-lg-2 col-md-5 col-8 inputfield pobox">
                  <div className="pobox-fields">
                    <label>PO Number</label>
                    <br />
                    <input
                      type="text"
                      disabled={abstractFieldDisable || !reportingAccessPerm}
                      value={abstractFieldDisable ? "" : poNumber}
                      onChange={(e) => {
                        setPoNumber(e.target.value);
                        e.target.value
                          ? setShowFieldDisable(true)
                          : setShowFieldDisable(false);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 inputfield shownamebox-wrapper">
                  <div className="shownamebox">
                    <label>Show Name</label>
                    <br />
                    {/* <select
                      value={showFieldDisable ? "" : showName}
                      onChange={onChangeShowName}
                      disabled={showFieldDisable}
                    >
                      <option></option>
                      {showList.map((item, idx) => {
                        return (
                          <option value={item.showId} key={idx}>
                            {item.showName}
                          </option>
                        );
                      })}
                    </select> */}
                    <Multiselect
                      id="css_custom"
                      disable={showFieldDisable || !reportingAccessPerm}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      placeholder=""
                      options={showList}
                      displayValue="showName"
                      selectionLimit="5"
                      showArrow="true"
                      ref={multiselectRef}
                      style={style}
                    />
                  </div>
                  <span>Or...</span>
                </div>
                <div className="col-lg-3 col-md-7 col-12 inputfield guestnamebox">
                  <div className="guestnamebox">
                    <label>Guest Name</label> <br />
                    <input
                      type="text"
                      disabled={abstractFieldDisable || !reportingAccessPerm}
                      value={abstractFieldDisable ? "" : name}
                      onChange={(e) => {
                        setName(e.target.value);
                        e.target.value
                          ? setShowFieldDisable(true)
                          : setShowFieldDisable(false);
                      }}
                    />
                  </div>
                  <div className="venuebox">
                    <label>Venue</label> <br />
                    <input
                      type="text"
                      disabled={abstractFieldDisable || !reportingAccessPerm}
                      value={abstractFieldDisable ? "" : venueName}
                      onChange={(e) => onVenueChange(e)}
                    />
                    {suggestionsListComponent}
                  </div>
                </div>
                <div className="col-lg-3 col-md-5 col-12">
                  {/* <div className="airdate inputfield">
                    <label>Air Date</label> <br />
                    <Datepicker
                      className="date-value"
                      dateFormat="MMMM d, yyyy"
                      disabled={showFieldDisable}
                      selected={selectedDate}
                      onChange={onChangeDate}
                    />
                    <i className="fa fa-calendar calendaricon"></i>
                  </div> */}
                  <div className="timebox row inputfield">
                    <div className="col-lg-5 col-5">
                      <label>Air Date From</label> <br />
                      <Datepicker
                        className="date-value"
                        dateFormat="MMMM d, yyyy"
                        disabled={!reportingAccessPerm}
                        selected={selectedDate}
                        onChange={onChangeDate}
                      />
                      <i className="fa fa-calendar calendaricon"></i>
                    </div>
                    <div className="col-lg-2 col-2 rangebox">
                      <label>Range</label> <br />
                      <input
                        type="checkbox"
                        checked={rangeParam}
                        disabled={!reportingAccessPerm}
                        onChange={onChangeRange}
                      />
                    </div>
                    <div className="col-lg-5 col-5">
                      <label>Air Date To</label> <br />
                      <Datepicker
                        className="date-value"
                        dateFormat="MMMM d, yyyy"
                        disabled={!rangeParam || !reportingAccessPerm}
                        selected={selectedToDate}
                        onChange={onChangeToDate}
                      />
                      <i className="fa fa-calendar calendaricon"></i>
                    </div>
                  </div>
                  <div className="row buttonbox">
                    <button
                      className="clrbtn"
                      onClick={onClear}
                      disabled={!reportingAccessPerm}
                    >
                      CLEAR
                    </button>
                    <button
                      className="searchbtn"
                      onClick={onSubmit}
                      disabled={!reportingAccessPerm}
                    >
                      SEARCH
                    </button>
                    <button
                      className="exportbtn"
                      onClick={onExport}
                      disabled={!reportingAccessPerm}
                    >
                      DATA EXPORT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sr-main">
        <h5>Search Results</h5>
        <img
          className="download"
          src={Download_icon}
          alt="Download"
          onClick={onDownloadExcel}
        />
        <div className="ag-theme-alpine sr_table_container">
          <AgGridReact
            columnDefs={columns}
            defaultColDef={defaultcolDef}
            rowData={searchList}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize="5"
            rowSelection={"single"}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </div>
      <LiveShotDetails formInfo={selectedRow} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showList: state.liveShotSearch.showList,
    studioTruckList: state.liveShotSearch.studioTruckList,
    searchList: state.liveShotSearch.searchList,
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchShowList: (token) => dispatch(actions.fetchShowList(token)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),
    onFetchUserList: (token) => dispatch(actions.fetchUserList(token)),
    onSearchForLiveshots: (token, queryParams) =>
      dispatch(actions.onSearchForLiveshots(token, queryParams)),
    onDownloadToExcel: (token, params) =>
      dispatch(actions.downloadToExcel(token, params)),
    onDownloadDataExport: (token, params) =>
      dispatch(actions.downloadDataExport(token, params)),
    onFetchCurrentUserRole: (sso, token) =>
      dispatch(actions.fetchCurrentUserRole(sso, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
