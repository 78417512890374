import React from "react";
import "./Button.css";

const button = (props) => (
  <button
    type={props.type}
    disabled={props.disabled}
    id={props.id}
    className={["Button", props.btnType].join(" ")}
    onClick={props.onClick}
    title={props.title}
  >
    {props.children}
  </button>
);

export default button;
