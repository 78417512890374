import * as actionTypes from "../actions/actionTypes";

const initialState = {
  accessToken: "",
  currentUser: "",
  loadingAuth: false,
};

const authSuccess = (state, action) => {
  return Object.assign({}, state, {
    accessToken: action.accessToken,
    currentUser: action.currentUser,
    loadingAuth: false,
  });
};

const authStart = (state, action) => {
  return Object.assign({}, state, {
    loadingAuth: true,
  });
};

const authEnd = (state, action) => {
  return Object.assign({}, state, {
    loadingAuth: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_END:
      return authEnd(state, action);
    default:
      return state;
  }
};

export default reducer;
