export const SHOW_LIST = "SHOW_LIST";
export const FETCH_STUDIOTRUCK_LIST = "FETCH_STUDIOTRUCK_LIST";
export const LIVESHOT_SEARCH_DATA = "LIVESHOT_SEARCH_DATA";
export const USER_LIST = "USER_LIST";
export const SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL";
export const HIDE_ERROR_MODAL = "HIDE_ERROR_MODAL";
export const STUDIOTRUCKSAVE_OBJECT_SUCCESS = "STUDIOTRUCKSAVE_OBJECT_SUCCESS";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_START = "AUTH_START";
export const AUTH_END = "AUTH_END";
export const SET_CURRENT_USER_ROLE = "SET_CURRENT_USER_ROLE";
