import axios from "../../axios";
import * as actionTypes from "./actionTypes";

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: data.access_token,
    currentUser: {
      sso: data.sso,
      firstName: data.firstName,
      lastName: data.lastName,
      userEmal: data.userEmal,
    },
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authEnd = () => {
  return {
    type: actionTypes.AUTH_END,
  };
};

export const authCheck = () => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get("/auth/token", { withCredentials: true })
      .then((response) => {
        console.log("auth res", response);
        localStorage.setItem("currentUser", JSON.stringify(response.data));
        dispatch(authSuccess(response.data));
      })
      .catch((err) => {
        dispatch(authEnd());
        console.log("error ", err);
      });
  };
};
