import React, { useEffect } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Home from "./components/home/Home";
import "./App.css";
import Login from "./components/login/Login";
import NoRole from "./components/norole/NoRole";
import * as actions from "./store/actions/index";
import { connect } from "react-redux";

const App = (props) => {
  const {
    onAuthCheck,
    onFetchCurrentUserRole,
    currentUserRole,
    currentUser,
    token,
  } = props;

  useEffect(() => {
    onAuthCheck();
  }, [onAuthCheck]);

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  useEffect(() => {
    if (currentUser) {
      onFetchCurrentUserRole(userId, token);
    }
  }, [onFetchCurrentUserRole, userId, currentUser, token]);

  let routes = (
    <Switch>
      <Route path="/" exact component={Home} />
      <Redirect to="/" />
    </Switch>
  );

  if (!props.isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Redirect from="*" to="/login" />
      </Switch>
    );
  }
  if (currentUserRole !== "" && currentUserRole.roleName === "NO ROLE") {
    routes = (
      <Switch>
        <Route path="/norole" exact component={NoRole} />
        <Redirect from="*" to="/norole" />
      </Switch>
    );
  }
  return routes;
};

const mapStateToProps = (state) => {
  let ret = {
    isAuthenticated: true,
    token: state.auth.accessToken,
    currentUserRole: state.user.currentUserRole,
    currentUser: state.auth.currentUser,
  };
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    ret = {
      isAuthenticated: state.auth.accessToken !== "",
      token: state.auth.accessToken,
      currentUser: state.auth.currentUser,
      currentUserRole: state.user.currentUserRole,
    };
  }
  return ret;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthCheck: () => dispatch(actions.authCheck()),
    onFetchCurrentUserRole: (sso, token) =>
      dispatch(actions.fetchCurrentUserRole(sso, token)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
