import * as actionTypes from "../actions/actionTypes";

const initialState = {
  userList: [],
  currentUserRole: "",
};

const userListSuccess = (state, action) => {
  return Object.assign({}, state, {
    userList: action.userList,
  });
};

const setCurrentUserRole = (state, action) => {
  return Object.assign({}, state, {
    currentUserRole: action.currentUserRole,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LIST:
      return userListSuccess(state, action);
    case actionTypes.SET_CURRENT_USER_ROLE:
      return setCurrentUserRole(state, action);
    default:
      return state;
  }
};

export default reducer;
